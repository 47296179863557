import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
} from "@chakra-ui/react";
import { usePermissioned } from "hooks";
import { InputCurrency, SingleDatePicker } from "components";
import MeetingsDetailsContext from "../context";
import ReactInputMask from "react-input-mask";
import { TbCalendar, TbClock } from "react-icons/tb";
import Schedules from "./schedules";
import Rescheduled from "./rescheduled";

const DateTime = () => {
  const { _id } = useParams();
  const { formData: parentFormData, setFormData: setParentFormData, formErrors } = useContext(MeetingsDetailsContext);
  const [formData, setFormData] = useState({});
  const isAllowedUpdateStartAndEndDate = usePermissioned("meetings:updateStartAndEndDate");
  const isDisabledStartAndEndDate = useMemo(
    () => (_.isString(_id) ? isAllowedUpdateStartAndEndDate === false : false),
    [_id, isAllowedUpdateStartAndEndDate]
  );

  useEffect(() => {
    setFormData(() => ({
      startDate: moment(parentFormData.startDate).format("HH:mm"),
      endDate: moment(parentFormData.endDate).format("HH:mm"),
    }));
  }, [parentFormData.startDate, parentFormData.endDate]);

  const handleBlurStartDate = useCallback(
    ({ target }) => {
      setParentFormData((state) => {
        const [hours, minutes] = target.value.split(":").map((v) => parseInt(v));
        const startDate = moment(state.startDate).startOf("day").add({ hours, minutes }).toDate();
        const endDate = moment(startDate).add(parentFormData.predictedDurationInMinutes, "minutes").toDate();
        return { ...state, startDate, endDate };
      });
    },
    [parentFormData.predictedDurationInMinutes]
  );

  const handleBlurEndDate = useCallback(
    ({ target }) => {
      setParentFormData((state) => {
        const [hours, minutes] = target.value.split(":").map((v) => parseInt(v));
        const endDate = moment(state.startDate).startOf("day").add({ hours, minutes }).toDate();
        return { ...state, endDate };
      });
    },
    [parentFormData.predictedDurationInMinutes]
  );

  return (
    <Fragment>
      <GridItem colSpan={{ base: 12, lg: 2 }}>
        <FormControl isRequired={true} isInvalid={formErrors.predictedDurationInMinutes}>
          <FormLabel fontSize="xs" mb="4px">
            Duração prevista
          </FormLabel>
          <InputGroup size="sm">
            <Input
              size="sm"
              as={InputCurrency}
              precision="0"
              value={parentFormData.predictedDurationInMinutes}
              onChange={(predictedDurationInMinutes) => setParentFormData((state) => ({ ...state, predictedDurationInMinutes }))}
            />
            <InputRightAddon fontSize="sm">minutos</InputRightAddon>
          </InputGroup>
          <FormErrorMessage>{formErrors.predictedDurationInMinutes}</FormErrorMessage>
        </FormControl>
      </GridItem>
      <GridItem colSpan={{ base: 12, lg: 3 }}>
        <FormControl flex="1" isRequired={true}>
          <FormLabel fontSize="xs" mb="4px">
            Data
          </FormLabel>
          <HStack>
            <InputGroup size="sm">
              <SingleDatePicker
                propsConfigs={{ inputProps: { size: "sm" } }}
                usePortal={true}
                // minDate={moment().startOf("day").toDate()}
                date={parentFormData.startDate}
                onDateChange={(startDate) =>
                  setParentFormData((state) => ({
                    ...state,
                    startDate,
                    endDate: moment(startDate).add(parentFormData.predictedDurationInMinutes, "minutes").toDate(),
                  }))
                }
                disabled={isDisabledStartAndEndDate}
              />
              <InputRightElement>
                <Icon as={TbCalendar} />
              </InputRightElement>
            </InputGroup>
            <Rescheduled />
          </HStack>
        </FormControl>
      </GridItem>
      <GridItem colSpan={{ base: 12, lg: 3 }}>
        <FormControl isRequired={true} isInvalid={formErrors.startDate || formErrors.endDate}>
          <FormLabel fontSize="xs" mb="4px">
            Horário
          </FormLabel>
          <HStack spacing="1px">
            <InputGroup size="sm">
              <InputLeftElement fontSize="sm">de</InputLeftElement>
              <Input
                size="sm"
                as={ReactInputMask}
                mask="99:99"
                borderRightRadius="0"
                value={formData.startDate}
                onChange={({ target }) => setFormData((state) => ({ ...state, startDate: target.value }))}
                onBlur={handleBlurStartDate}
                isDisabled={isDisabledStartAndEndDate}
              />
              <InputRightElement>
                <Icon as={TbClock} />
              </InputRightElement>
            </InputGroup>
            <InputGroup size="sm">
              <InputLeftElement fontSize="sm">às</InputLeftElement>
              <Input
                size="sm"
                as={ReactInputMask}
                mask="99:99"
                borderLeftRadius="0"
                value={formData.endDate}
                onChange={({ target }) => setFormData((state) => ({ ...state, endDate: target.value }))}
                onBlur={handleBlurEndDate}
                isDisabled={isDisabledStartAndEndDate}
              />
              <InputRightElement>
                <Icon as={TbClock} />
              </InputRightElement>
            </InputGroup>
            <Schedules />
          </HStack>
          <FormErrorMessage>{formErrors.startDate || formErrors.endDate}</FormErrorMessage>
        </FormControl>
      </GridItem>
    </Fragment>
  );
};

export default DateTime;
