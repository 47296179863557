import { useCallback } from "react";
import moment from "moment";
import { messages } from "consts";

const normalizeString = (value) =>
  value
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();

export const useFileNamePattern = ({ dueDate, customer }) => {
  return useCallback(
    (file) => {
      const isSame = (() => {
        const match = file.name.match(/\b\d{4}_\d{2}\b/);
        if (match) {
          const fileDate = moment(match[0], "YYYY_MM");
          const prevMonth = moment(dueDate).subtract(1, "month");
          if (fileDate.isSame(prevMonth, "month")) return true;
        }
        return false;
      })();
      if (isSame && customer && normalizeString(file.name).includes(normalizeString(customer.tradingName))) return;
      throw new Error(messages.error.fileNamePattern);
    },
    [dueDate, customer]
  );
};
