export const messages = {
  success: {
    saveData: "Registro salvo com sucesso!",
    deleteData: "Registro removido com sucesso!",
    passwordResetEmail: "E-mail enviado com sucesso!",
    updatePassword: "A senha foi alterada com sucesso!",
  },
  error: {
    saveData: "Problemas ao salvar registro.",
    deleteData: "Problemas ao remover registro.",
    required: "Este campo é obrigatório.",
    invalidName: "O nome informado é inválido",
    invalidEmail: "O e-mail informado é inválido.",
    invalidDate: "A data informada é inválida.",
    unavailableSchedule: "O horário informado está indisponível.",
    invalidDuration: "A duração informada é inválida.",
    greaterOrEqual: "O valor informado deve ser maior ou igual a ",
    lessOrEqual: "O valor informado deve ser menor ou igual a ",
    moreThan: "O valor informado deve ser maior que ",
    lessThan: "O valor informado deve ser menor que ",
    passwordsMustMatch: "As novas senhas informadas devem ser iguais.",
    invalidLatitudeLongitude: "A latitude/longitude informada é inválida.",
    importListSize: "A lista de importação deve conter no máximo 500 registros.",
    importNotFoundIndicatorTypes: "Alguns itens da importação não foram encontrados na lista de tipos indicadores: ",
    meetingNotFound: "A reunião informada não foi encontrada.",
    invalidPauseStartedAt: "O horário de início da pausa deve ser maior que o horário de início da tarefa.",
    invalidPauseFinishedAt: "O horário de fim da pausa deve ser menor que o horário de fim da tarefa.",
    required: "Este campo é obrigatório.",
    invalidLatitudeLongitude: "A latitude/longitude informada é inválida.",
    unscheduledMeetingExists: "Existe uma reunião deste tipo com status previsto para o cliente atual.",
    endDateLowerThanEndDate: "A data de fim deve ser maior que a data de início.",
    customerFileName: "O nome do arquivo não corresponde ao cliente atual.",
    fileSizeLimit: "O arquivo ultrapassa o tamanho limite permitido de ",
    fileNamePattern:
      "O nome do arquivo deve seguir o padrão 'Cliente Ano_Mês (Formatar 2024_10)'. Lembre-se que o Mês deve ser M-1 da data de vencimento da tarefa.",
  },
  warning: {
    deleteDataConfirm: "Deseja realmente excluir este registro?",
    closeDataConfirm: "Esta é uma ação irreversível. Você está realizando o fechamento deste registro. Deseja realmente continuar?",
  },
};
